import axios from 'axios'
import 'url-search-params-polyfill'
import moment from 'moment/moment'
// import availability actions
import fetchPackageBrand from './fetchPackageBrand'
import fetchSso, { fetchSsoError } from './fetchSso'
import {
  setActiveBrand,
  setActiveBookingType,
  setActiveCruiseType,
  setActiveCurrency,
  setActiveStartDate,
  setActiveEndDate,
  setActiveSailingNights,
  setActivePackageCode,
  setActiveShip,
  setActivePort,
  setActiveRegion,
  setActiveSeniorCitizenDiscount,
  setActiveServiceMemberDiscount,
  setActivePoliceDiscount,
  setActiveFireFighterDiscount,
  setActiveResidentOf,
  setActiveSso,
  setActiveCount,
  setActivePage,
  setActiveSortBy,
  // setActiveLoyalty,
  setActiveGuestCount
} from './setActiveSearchFilters'

import updateSearchParams from '../utilities/updateSearchParams'
import triggerCustomGaEvent from '../utilities/triggerCustomGaEvent'
// activeSearchByPriceTableActions
import {
  setActivePriceTableBrandFilter,
  setActivePriceTableDestinationFilter,
  setActivePriceTableCurrencyFilter
} from './setActivePriceTableSearchFilters'
import fetchStaticData from './fetchStaticData'
// config actions
export const FETCH_CONFIG_BEGIN = 'FETCH_CONFIG_BEGIN'
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS'
export const FETCH_CONFIG_FAILURE = 'FETCH_CONFIG_FAILURE'
export const SET_CURRENT_QUERY_PARAMS = 'SET_CURRENT_QUERY_PARAMS'
export const SET_REFERER = 'SET_REFERER'

export const fetchConfigBegin = () => ({
  type: FETCH_CONFIG_BEGIN
})

const setReferer = (source, data) => ({
  type: SET_REFERER,
  payload: {
    source,
    data
  }
})

export const fetchConfigSuccess = config => ({
  type: FETCH_CONFIG_SUCCESS,
  payload: config
})

export const fetchConfigError = error => ({
  type: FETCH_CONFIG_FAILURE,
  payload: { error }
})

export const setCurrentQueryParams = queryParams => ({
  type: SET_CURRENT_QUERY_PARAMS,
  payload: { queryParams }
})

// Handle initial config request
export default function fetchConfiguration() {
  return async (dispatch, getState) => {
    // Import Query string & Parse URL
    const queryString = require('query-string')
    const urlQuery = queryString.parse(window.location.search),
      ssoData = getState().ssoData

    let agentQueryParam = {}

    // Set default lang
    let currentLanguage = 'en-US'

    // Use lang query param
    if (urlQuery.language) {
      currentLanguage = urlQuery.language
    }

    let currentCurrency = 'USD'

    if (urlQuery.currency) {
      currentCurrency = urlQuery.currency
    } else if (urlQuery.Currency) {
      currentCurrency = urlQuery.Currency
    } else if (urlQuery.cur) {
      currentCurrency = urlQuery.cur
    } else if (urlQuery.Cur) {
      currentCurrency = urlQuery.Cur
    } else if (
      ssoData &&
      ssoData.agency &&
      ssoData.agency.transactionData &&
      ssoData.agency.transactionData.defaultCurrencyCode
    ) {
      currentCurrency = ssoData.agency.transactionData.defaultCurrencyCode
    }

    let currentStartDate = null,
      currentEndDate = null,
      currentCount = null

    if (urlQuery.startDate) {
      currentStartDate = moment(urlQuery.startDate).format('YYYY-MM-DD')
    } else if (urlQuery.DepartureDate) {
      currentStartDate = moment(urlQuery.DepartureDate).format('YYYY-MM-DD')
      currentCount = 30
    }

    if (urlQuery.startDate && urlQuery.endDate) {
      currentEndDate = moment(urlQuery.endDate).format('YYYY-MM-DD')
    } else {
      currentCount = 30
    }

    if (urlQuery.count) {
      currentCount = urlQuery.count
    }

    let currentStateCode = null

    if (urlQuery.stateCode) {
      currentStateCode = urlQuery.stateCode
    } else if (urlQuery.residentOf) {
      currentStateCode = urlQuery.residentOf
    }

    // URL Params
    let urlParams = {
      brand: urlQuery.brand,
      bookingType: urlQuery.bookingType,
      cruiseType: urlQuery.cruiseType,
      currency: currentCurrency,
      startDate: currentStartDate,
      endDate: currentEndDate,
      count: currentCount,
      page: urlQuery.page,
      duration: urlQuery.duration,
      ship: urlQuery.ship,
      port: urlQuery.port,
      region: urlQuery.region,
      seniorReqd: urlQuery.seniorReqd,
      militaryReqd: urlQuery.militaryReqd,
      policeReqd: urlQuery.policeReqd,
      fireFighterReqd: urlQuery.fireFighterReqd,
      stateCode: currentStateCode,
      language: currentLanguage
    }

    // Set active filter states
    if (urlQuery.brand) {
      dispatch(setActiveBrand(urlQuery.brand))
      dispatch(setActivePriceTableBrandFilter(urlQuery.brand))
    }
    if (urlQuery.bookingType) {
      dispatch(setActiveBookingType(urlQuery.bookingType))
    }
    if (urlQuery.cruiseType) {
      dispatch(setActiveCruiseType(urlQuery.cruiseType))
    }
    if (currentCurrency) {
      dispatch(setActiveCurrency(currentCurrency))
      dispatch(setActivePriceTableCurrencyFilter(currentCurrency))
    }
    if (urlQuery.startDate) {
      dispatch(
        setActiveStartDate(moment(urlQuery.startDate).format('YYYY-MM-DD'))
      )
    } else if (urlQuery.DepartureDate) {
      dispatch(
        setActiveStartDate(moment(urlQuery.DepartureDate).format('YYYY-MM-DD'))
      )
    }
    if (urlQuery.endDate) {
      dispatch(setActiveEndDate(moment(urlQuery.endDate).format('YYYY-MM-DD')))
    }
    if (currentCount) {
      dispatch(setActiveCount(currentCount))
    }
    if (urlQuery.page) {
      dispatch(setActivePage(urlQuery.page))
    }

    if (urlQuery.duration) {
      dispatch(setActiveSailingNights(urlQuery.duration))
    }
    if (urlQuery.ship) {
      dispatch(setActiveShip(urlQuery.ship))
    }
    if (urlQuery.port) {
      dispatch(setActivePort(urlQuery.port))
    }
    // no longer works as we now need the tier code
    // if (urlQuery.loyalty) {
    //   dispatch(setActiveLoyalty(urlQuery.loyalty))
    // }
    if (urlQuery.region) {
      dispatch(setActiveRegion(urlQuery.region))
      dispatch(setActivePriceTableDestinationFilter(urlQuery.region))
    }
    if (urlQuery.seniorReqd) {
      dispatch(setActiveSeniorCitizenDiscount(urlQuery.seniorReqd))
    } else if (urlQuery.sr) {
      dispatch(setActiveSeniorCitizenDiscount(urlQuery.sr))
    }
    if (urlQuery.militaryReqd) {
      dispatch(setActiveServiceMemberDiscount(urlQuery.militaryReqd))
    } else if (urlQuery.sm) {
      dispatch(setActiveServiceMemberDiscount(urlQuery.sm))
    }
    if (urlQuery.policeReqd) {
      dispatch(setActivePoliceDiscount(urlQuery.policeReqd))
    } else if (urlQuery.fa) {
      dispatch(setActivePoliceDiscount(urlQuery.fa))
    }
    if (urlQuery.fireFighterReqd) {
      dispatch(setActiveFireFighterDiscount(urlQuery.fireFighterReqd))
    } else if (urlQuery.st) {
      dispatch(setActiveFireFighterDiscount(urlQuery.st))
    }
    if (currentStateCode) {
      dispatch(setActiveResidentOf(currentStateCode))
    }
    if (urlQuery.sso) {
      dispatch(setActiveSso(urlQuery.sso))
    } else if (urlQuery.z) {
      dispatch(setActiveSso(urlQuery.z))

      agentQueryParam = {
        sso: urlQuery.z
      }

      updateSearchParams(agentQueryParam)
    } else if (urlQuery.cptok) {
      dispatch(setActiveSso(urlQuery.cptok))

      agentQueryParam = {
        sso: urlQuery.cptok
      }

      updateSearchParams(agentQueryParam)
    }

    if (urlQuery.sortBy) {
      dispatch(setActiveSortBy(urlQuery.sortBy))
    }

    if (urlQuery.PackageCode) {
      dispatch(setActivePackageCode(urlQuery.PackageCode))
      await dispatch(fetchPackageBrand(urlQuery.PackageCode))
    }

    if (urlQuery.guestCount) {
      dispatch(setActiveGuestCount(urlQuery.guestCount))
    } else if (urlQuery.occupancy) {
      dispatch(setActiveGuestCount(urlQuery.occupancy))
    }

    // Fetch Query string method
    const fetchQuery = new URLSearchParams()

    // Loop urlParams for undefined values & remove
    for (let key in urlParams) {
      if (typeof urlParams[key] === 'undefined') continue
      fetchQuery.append(key, urlParams[key])
    }

    // Base API path
    let apiBase = process.env.REACT_APP_BASE_ENDPOINT_URL + 'config?'

    // Combine API URL & formatted urlParams
    const fetchResult = apiBase + fetchQuery.toString()

    dispatch(setCurrentQueryParams(urlParams))

    if (urlQuery.referrer) {
      dispatch(
        setReferer(urlQuery.referrer, {
          packageCode: urlQuery.PackageCode,
          departureData: urlQuery.DepartureDate
        })
      )
    }

    dispatch(fetchConfigBegin())
    return axios
      .get(fetchResult, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json'
        }
      })
      .then(response => {
        // Narrow results to hydra member see IPS spec for details
        const configuration = response.data['hydra:member'].map(config => {
          return config
        })
        dispatch(fetchConfigSuccess(configuration))

        // Dispatch initial GA events
        triggerCustomGaEvent('analytics-pageview', window.location.href)

        dispatch(fetchStaticData())

        if (urlQuery.sso || urlQuery.z || urlQuery.cptok) {
          dispatch(fetchSso())
        } else {
          dispatch(fetchSsoError('Something went wrong'))
        }
      })
      .catch(error => dispatch(fetchConfigError(error)))
  }
}
