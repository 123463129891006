export const SET_ACTIVE_BRAND = 'SET_ACTIVE_BRAND'
export const SET_ACTIVE_BOOKING_TYPE = 'SET_ACTIVE_BOOKING_TYPE'
export const SET_ACTIVE_CRUISE_TYPE = 'SET_ACTIVE_CRUISE_TYPE'
export const SET_ACTIVE_CURRENCY = 'SET_ACTIVE_CURRENCY'
export const SET_ACTIVE_START_DATE = 'SET_ACTIVE_START_DATE'
export const SET_ACTIVE_END_DATE = 'SET_ACTIVE_END_DATE'
export const SET_ACTIVE_SAILING_NIGHTS = 'SET_ACTIVE_SAILING_NIGHTS'
export const SET_ACTIVE_SHIP = 'SET_ACTIVE_SHIP'
export const SET_ACTIVE_PORT = 'SET_ACTIVE_PORT'
export const SET_ACTIVE_REGION = 'SET_ACTIVE_REGION'
export const SET_ACTIVE_SENIOR_CITIZEN_DISCOUNT =
  'SET_ACTIVE_SENIOR_CITIZEN_DISCOUNT'
export const SET_ACTIVE_SERVICE_MEMBER_DISCOUNT =
  'SET_ACTIVE_SERVICE_MEMBER_DISCOUNT'
export const SET_ACTIVE_POLICE_DISCOUNT = 'SET_ACTIVE_POLICE_DISCOUNT'
export const SET_ACTIVE_FIRE_FIGHTER_DISCOUNT =
  'SET_ACTIVE_FIRE_FIGHTER_DISCOUNT'
export const SET_ACTIVE_RESIDENT = 'SET_ACTIVE_RESIDENT'
export const SET_ACTIVE_LOYALTY = 'SET_ACTIVE_LOYALTY'
export const SET_ACTIVE_SSO = 'SET_ACTIVE_SSO'
export const SET_ACTIVE_COUNT = 'SET_ACTIVE_COUNT'
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE'
export const SET_ACTIVE_PAGINATION = 'SET_ACTIVE_PAGINATION'
export const RESET_ACTIVE_PAGINATION = 'RESET_ACTIVE_PAGINATION'
export const SET_ACTIVE_SORT_BY = 'SET_ACTIVE_SORT_BY'
export const SET_ACTIVE_PACKAGE_CODE = 'SET_ACTIVE_PACKAGE_CODE'
export const SET_END_DATE_VISIBILITY = 'SET_END_DATE_VISIBILITY'
export const SET_GUEST_COUNT = 'SET_GUEST_COUNT'
export const CLEAR_PACKAGE_CODE = 'CLEAR_PACKAGE_CODE'

export const updateBrand = brand => ({
  type: SET_ACTIVE_BRAND,
  payload: brand
})
export const updateBookingType = bookingType => ({
  type: SET_ACTIVE_BOOKING_TYPE,
  payload: bookingType
})
export const updateCruiseType = cruiseType => ({
  type: SET_ACTIVE_CRUISE_TYPE,
  payload: cruiseType
})
export const updateCurrency = currency => ({
  type: SET_ACTIVE_CURRENCY,
  payload: currency
})
export const updateStartDate = startDate => ({
  type: SET_ACTIVE_START_DATE,
  payload: startDate
})
export const updateEndDate = endDate => ({
  type: SET_ACTIVE_END_DATE,
  payload: endDate
})
export const updateSailingNights = nights => ({
  type: SET_ACTIVE_SAILING_NIGHTS,
  payload: nights
})
export const updateShip = ship => ({
  type: SET_ACTIVE_SHIP,
  payload: ship
})
export const updatePort = port => ({
  type: SET_ACTIVE_PORT,
  payload: port
})
export const updateRegion = region => ({
  type: SET_ACTIVE_REGION,
  payload: region
})
export const updateSeniorCitizenDiscount = isSeniorCitizen => ({
  type: SET_ACTIVE_SENIOR_CITIZEN_DISCOUNT,
  payload: isSeniorCitizen
})
export const updateServiceMemberDiscount = isServiceMember => ({
  type: SET_ACTIVE_SERVICE_MEMBER_DISCOUNT,
  payload: isServiceMember
})
export const updatePoliceDiscount = isPolice => ({
  type: SET_ACTIVE_POLICE_DISCOUNT,
  payload: isPolice
})
export const updateFireFighterDiscount = isFireFighter => ({
  type: SET_ACTIVE_FIRE_FIGHTER_DISCOUNT,
  payload: isFireFighter
})
export const updateResident = currentResidency => ({
  type: SET_ACTIVE_RESIDENT,
  payload: currentResidency
})
export const updateLoyalty = (loyalty, tier) => ({
  type: SET_ACTIVE_LOYALTY,
  payload: { loyalty, tier }
})
export const updateSso = sso => ({
  type: SET_ACTIVE_SSO,
  payload: sso
})
export const updateCount = count => ({
  type: SET_ACTIVE_COUNT,
  payload: count
})
export const updatePage = page => ({
  type: SET_ACTIVE_PAGE,
  payload: page
})
export const updatePagination = pagination => ({
  type: SET_ACTIVE_PAGINATION,
  payload: pagination
})
export const resetPagination = pagination => ({
  type: RESET_ACTIVE_PAGINATION,
  payload: pagination
})

export const setActiveGuestCount = count => ({
  type: SET_GUEST_COUNT,
  payload: count
})

export const updateSortBy = sortBy => ({
  type: SET_ACTIVE_SORT_BY,
  payload: sortBy
})
export const updatePackage = packageCode => ({
  type: SET_ACTIVE_PACKAGE_CODE,
  payload: packageCode
})
export const updateEndDataVisibility = endDateVisible => ({
  type: SET_END_DATE_VISIBILITY,
  payload: endDateVisible
})

export function setActiveBrand(brand) {
  return dispatch => {
    dispatch(updateBrand(brand))
  }
}

export function setActiveBookingType(bookingType) {
  return dispatch => {
    dispatch(updateBookingType(bookingType))
  }
}

export function setActiveCruiseType(cruiseType) {
  return dispatch => {
    dispatch(updateCruiseType(cruiseType))
  }
}

export function setActiveCurrency(currency) {
  return dispatch => {
    dispatch(updateCurrency(currency))
  }
}

export function setActiveStartDate(startDate) {
  return dispatch => {
    dispatch(updateStartDate(startDate))
  }
}

export function setActiveEndDate(endDate) {
  return dispatch => {
    dispatch(updateEndDate(endDate))
  }
}

export function setActiveSailingNights(nights) {
  return dispatch => {
    dispatch(updateSailingNights(nights))
  }
}

export function setActiveShip(ship) {
  return dispatch => {
    dispatch(updateShip(ship))
  }
}

export function setActivePort(port) {
  return dispatch => {
    dispatch(updatePort(port))
  }
}

export function setActiveRegion(region) {
  return dispatch => {
    dispatch(updateRegion(region))
  }
}

export function setActiveSeniorCitizenDiscount(isSeniorCitizen) {
  return dispatch => {
    dispatch(updateSeniorCitizenDiscount(isSeniorCitizen))
  }
}

export function setActiveServiceMemberDiscount(isServiceMember) {
  return dispatch => {
    dispatch(updateServiceMemberDiscount(isServiceMember))
  }
}

export function setActivePoliceDiscount(isPolice) {
  return dispatch => {
    dispatch(updatePoliceDiscount(isPolice))
  }
}

export function setActiveFireFighterDiscount(isFireFighter) {
  return dispatch => {
    dispatch(updateFireFighterDiscount(isFireFighter))
  }
}

export function setActiveResidentOf(currentResidency) {
  return dispatch => {
    dispatch(updateResident(currentResidency))
  }
}

export function setActiveLoyalty(loyalty, tier) {
  return dispatch => {
    dispatch(updateLoyalty(loyalty, tier))
  }
}

export function setActiveSso(sso) {
  return dispatch => {
    dispatch(updateSso(sso))
  }
}

export function setActiveCount(count) {
  return dispatch => {
    dispatch(updateCount(count))
  }
}

export function setActivePage(page) {
  return dispatch => {
    dispatch(updatePage(page))
  }
}

export function setActivePagination(pagination) {
  return dispatch => {
    dispatch(updatePagination(pagination))
  }
}
export function resetActivePagination(pagination) {
  return dispatch => {
    dispatch(resetPagination(pagination))
  }
}

export function setActiveSortBy(sortBy) {
  return dispatch => {
    dispatch(updateSortBy(sortBy))
  }
}

export function setActivePackageCode(packageCode) {
  return dispatch => {
    dispatch(updatePackage(packageCode))
  }
}

export function setEndDateVisibility(endDateVisible) {
  return dispatch => {
    dispatch(updateEndDataVisibility(endDateVisible))
  }
}

export function clearPackageCode() {
  return dispatch => dispatch({ type: CLEAR_PACKAGE_CODE })
}
