import axios from 'axios'
import 'url-search-params-polyfill'
import setCruiseTours from './setCruiseTours'
import triggerCustomGaEvent from '../utilities/triggerCustomGaEvent'
import formatVPSPackages from '../utilities/formatVPSPackages'
import { setLandPackages } from './setLandPackages'
import { setActivePagination } from './setActiveSearchFilters'
import formatLandPackages from '../utilities/formatLandPackages'
import { formatVPSCruiseRequestParams } from '../utilities/formatPackageRequest'
import { currencySymbol } from '../configuration/currencies'

//  see comment below
// import { allowedLandPackagesBrands } from '../configuration/brands'

export const FETCH_PACKAGES_BEGIN = 'FETCH_PACKAGES_BEGIN'
export const FETCH_PACKAGES_SUCCESS = 'FETCH_PACKAGES_SUCCESS'
export const FETCH_PACKAGES_FAILURE = 'FETCH_PACKAGES_FAILURE'
export const CLEAR_PACKAGE_DATA = 'CLEAR_PACKAGE_DATA'
export const fetchPackagesBegin = () => ({
  type: FETCH_PACKAGES_BEGIN
})

export const fetchPackagesSuccess = packages => ({
  type: FETCH_PACKAGES_SUCCESS,
  payload: packages
})

export const fetchPackagesError = error => ({
  type: FETCH_PACKAGES_FAILURE,
  payload: { error }
})

export const clearPackageData = () => ({ type: CLEAR_PACKAGE_DATA })

// Handle Availability Fetch & Functionality
export default function fetchPackages(ssoAgent) {
  return (dispatch, getState) => {
    dispatch({ type: CLEAR_PACKAGE_DATA })
    dispatch(fetchPackagesBegin())
    const state = getState()
    const activeSearchFilterData = getState().activeSearchFilterData

    /*
     * We are going to exclude Cruisetours if only searching Azamara.
     * This was requested by the client as they have incorrect, or old data.
     * We are also going to filter out any Cruisetour results return for Azamara if searching
     * more brands.
     * */

    const shouldFetchCruiseTours = Boolean(
      process.env.REACT_APP_INCLUDE_CRUISE_TOURS === 'true' &&
        activeSearchFilterData.brand !== 'Z'
    )

    let urlParams = formatVPSCruiseRequestParams(
      state,
      activeSearchFilterData,
      ssoAgent,
      shouldFetchCruiseTours
    )

    // With the removal of Azamara this is no longer needed.
    // Leaving as a comment in case it gets added by other brands, or Aza returns.
    // const includeLandPackages =
    //   process.env.REACT_APP_INCLUDE_LAND_PACKAGES === 'true' &&
    //   activeSearchFilterData.brand.split(',').some(selectedBrand => {
    //     return allowedLandPackagesBrands.some(brand => brand === selectedBrand)
    //   })
    //
    // if (includeLandPackages) {
    //   // occupancy is only used by IPS for land-programs.
    //   // Because we hide 4th pricing when searching Azamara, we need
    //   // to override guestCount when it's 4.
    //   // If occupancy is ever needed for anything else, we'll need to update this with the IPS team.
    //   const occupancy =
    //     includeLandPackages && Number(activeSearchFilterData.guestCount) === 4
    //       ? '3'
    //       : activeSearchFilterData.guestCount
    //   urlParams.includeLandPackages = true
    //   urlParams.occupancy = occupancy
    // }

    // Fetch Query string method
    const fetchQuery = new URLSearchParams()

    // Loop urlParams for undefined values & remove
    for (let key in urlParams) {
      if (
        typeof urlParams[key] === 'undefined' ||
        urlParams[key] === '' ||
        urlParams[key] === false
      ) {
        continue
      }

      if (key === 'duration') {
        fetchQuery.append('duration[match][]', urlParams[key])
      } else {
        fetchQuery.append(key, urlParams[key])
      }
    }

    const guestCount = activeSearchFilterData.guestCount
      ? Number(activeSearchFilterData.guestCount)
      : 2

    // Base API path
    // const packagesBase = process.env.REACT_APP_FLOW_ENDPOINT_URL + 'cruise?'
    const packagesBase = process.env.REACT_APP_FLOW_ENDPOINT_URL + 'vps-cruise?'

    // Formatted API path including base and params
    const packagesApi = packagesBase + fetchQuery.toString()

    return axios
      .get(packagesApi)
      .then(response => {
        let availableCruises, availableCruiseTours

        if (
          response.data &&
          response.data['hydra:member'] &&
          response.data['hydra:member'][0] &&
          response.data['hydra:member'][0].packages
        ) {
          const packages = formatVPSPackages(
            response.data['hydra:member'][0].packages,
            guestCount,
            activeSearchFilterData.currency,
            currencySymbol[activeSearchFilterData.currency].symbol || '$',
            currencySymbol[activeSearchFilterData.currency].placement || 'start'
          )

          if (packages) {
            availableCruises = packages.reduce((obj, packageData) => {
              if (packageData.justACruise) {
                const key = `${packageData.id}-${packageData.sailDate}`
                obj[key] = {
                  ...packageData,
                  identifier: key,
                  stateKey: 'packagesData'
                }
              }
              return obj
            }, {})

            availableCruiseTours = packages.reduce((obj, packageData) => {
              if (
                packageData.hasOwnProperty('justACruise') &&
                !packageData.justACruise &&
                packageData.brand !== 'Z' // remove Azamara
              ) {
                const key = `${packageData.id}-${packageData.sailDate}`
                obj[key] = {
                  ...packageData,
                  identifier: key,
                  stateKey: 'cruiseToursData'
                }
              }
              return obj
            }, {})
          }

          if (
            response.data['hydra:member'][0].prePost &&
            response.data['hydra:member'][0].prePost.landPackages
          ) {
            const landPackages = formatLandPackages(
              response.data['hydra:member'][0].prePost.landPackages
            )
            dispatch(setLandPackages(landPackages))
          }

          // set pagination values
          if (response.data['hydra:member'][0].pagination) {
            dispatch(
              setActivePagination(response.data['hydra:member'][0].pagination)
            )
          }
        }

        if (availableCruises) {
          dispatch(fetchPackagesSuccess(availableCruises))
          // Dispatch GA event for cruises
          triggerCustomGaEvent(
            'equoteTracking-cruisesRequest',
            availableCruises
          )
        }

        if (availableCruiseTours) {
          dispatch(setCruiseTours(availableCruiseTours))
          // Dispatch GA event for cruises
          triggerCustomGaEvent(
            'equoteTracking-cruiseToursRequest',
            availableCruiseTours
          )
        }
      })
      .catch(error => {
        dispatch(fetchPackagesError(error))
      })
  }
}
