import {
  SET_ACTIVE_BRAND,
  SET_ACTIVE_BOOKING_TYPE,
  SET_ACTIVE_CRUISE_TYPE,
  SET_ACTIVE_CURRENCY,
  SET_ACTIVE_START_DATE,
  SET_ACTIVE_END_DATE,
  SET_ACTIVE_SAILING_NIGHTS,
  SET_ACTIVE_SHIP,
  SET_ACTIVE_PORT,
  SET_ACTIVE_REGION,
  SET_ACTIVE_SENIOR_CITIZEN_DISCOUNT,
  SET_ACTIVE_SERVICE_MEMBER_DISCOUNT,
  SET_ACTIVE_POLICE_DISCOUNT,
  SET_ACTIVE_FIRE_FIGHTER_DISCOUNT,
  SET_ACTIVE_RESIDENT,
  SET_ACTIVE_LOYALTY,
  SET_ACTIVE_SSO,
  SET_ACTIVE_COUNT,
  SET_ACTIVE_PAGE,
  SET_ACTIVE_PAGINATION,
  RESET_ACTIVE_PAGINATION,
  SET_ACTIVE_SORT_BY,
  SET_ACTIVE_PACKAGE_CODE,
  SET_END_DATE_VISIBILITY,
  SET_GUEST_COUNT,
  CLEAR_PACKAGE_CODE
} from '../actions/setActiveSearchFilters'
import { RESET_ACTIVE_FILTERS } from '../actions/resetQuoteFlow'

const initialState = {
  bookingType: 'B', // I | B
  cruiseType:
    process.env.REACT_APP_INCLUDE_CRUISE_TOURS === 'true' ? 'CO,CT' : 'CO',
  guestCount: 2,
  endDateVisible: false,
  brand: 'C', // C | R
  page: 1,
  perPage: 30,
  pages: 0,
  total: 0
}

export default function setActiveSearchFiltersReducers(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_ACTIVE_BRAND:
      return {
        ...state,
        brand: action.payload
      }

    case SET_ACTIVE_BOOKING_TYPE:
      return {
        ...state,
        bookingType: action.payload
      }

    case SET_ACTIVE_CRUISE_TYPE:
      return {
        ...state,
        cruiseType: action.payload
      }

    case SET_ACTIVE_CURRENCY:
      return {
        ...state,
        currency: action.payload
      }

    case SET_ACTIVE_START_DATE:
      return {
        ...state,
        startDate: action.payload
      }

    case SET_ACTIVE_END_DATE:
      return {
        ...state,
        endDate: action.payload
      }

    case SET_ACTIVE_SAILING_NIGHTS:
      return {
        ...state,
        duration: action.payload
      }

    case SET_ACTIVE_SHIP:
      return {
        ...state,
        ship: action.payload
      }

    case SET_ACTIVE_PORT:
      return {
        ...state,
        port: action.payload
      }

    case SET_ACTIVE_REGION:
      return {
        ...state,
        region: action.payload
      }

    case SET_ACTIVE_SENIOR_CITIZEN_DISCOUNT:
      return {
        ...state,
        isSeniorCitizen: action.payload
      }

    case SET_ACTIVE_SERVICE_MEMBER_DISCOUNT:
      return {
        ...state,
        isServiceMember: action.payload
      }

    case SET_ACTIVE_POLICE_DISCOUNT:
      return {
        ...state,
        isPolice: action.payload
      }

    case SET_ACTIVE_FIRE_FIGHTER_DISCOUNT:
      return {
        ...state,
        isFireFighter: action.payload
      }

    case SET_ACTIVE_RESIDENT:
      return {
        ...state,
        residentOf: action.payload
      }

    case SET_ACTIVE_LOYALTY:
      return {
        ...state,
        loyaltyId: action.payload.loyalty || '',
        loyaltyTier: action.payload.tier || ''
      }

    case SET_ACTIVE_SSO:
      return {
        ...state,
        sso: action.payload
      }

    case SET_ACTIVE_COUNT:
      return {
        ...state,
        count: action.payload
      }

    case SET_ACTIVE_PAGE:
      return {
        ...state,
        page: action.payload
      }

    case SET_ACTIVE_PAGINATION:
      return {
        ...state,
        page: action.payload.page,
        perPage: action.payload.perPage,
        pages: action.payload.pages,
        total: action.payload.total
      }

    case RESET_ACTIVE_PAGINATION:
      return {
        ...state,
        page: 1
      }

    case SET_ACTIVE_SORT_BY:
      return {
        ...state,
        sortBy: action.payload
      }

    case SET_ACTIVE_PACKAGE_CODE:
      return {
        ...state,
        packageCode: action.payload
      }

    case SET_END_DATE_VISIBILITY:
      return {
        ...state,
        endDateVisible: action.payload
      }
    case SET_GUEST_COUNT:
      return {
        ...state,
        guestCount: action.payload
      }
    case CLEAR_PACKAGE_CODE:
      return {
        ...state,
        packageCode: null
      }
    case RESET_ACTIVE_FILTERS:
      return {
        sso: state.sso,
        bookingType: state.bookingType,
        cruiseType: state.cruiseType,
        currency: state.currency,
        count: state.count,
        page: state.page,
        guestCount: 2
      }

    default:
      return state
  }
}
