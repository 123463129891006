import { get } from 'lodash'
import { getAgentCountry } from '../reducers/fetchSsoReducers'

export const formatVPSCruiseRequestParams = (
  state,
  filterData,
  ssoAgent,
  shouldFetchCruiseTours
) => {
  return {
    brand: filterData.brand,
    bookingType: filterData.bookingType,
    cruiseType: shouldFetchCruiseTours ? 'CO,CT' : 'CO',
    currency: filterData.currency,
    startDate: filterData.startDate,
    endDate: filterData.endDate,
    count: filterData.count,
    duration: filterData.duration,
    page: filterData.page,
    ship: filterData.ship,
    seniorReqd: filterData.isSeniorCitizen,
    militaryReqd: filterData.isServiceMember,
    policeReqd: filterData.isPolice,
    fireFighterReqd: filterData.isFireFighter,
    agencyId: get(ssoAgent, 'transactionData.agency.agencyId', ''),
    officeCode: get(ssoAgent, 'transactionData.officeCode', ''),
    occupancy: filterData.guestCount,
    country: getAgentCountry(state) || get(ssoAgent, 'agent.country', '')
  }
}

export const formatCruiseRequestParams = (
  state,
  filterData,
  ssoAgent,
  shouldFetchCruiseTours
) => {
  return {
    brand: filterData.brand,
    bookingType: filterData.bookingType,
    cruiseType: shouldFetchCruiseTours ? 'CO,CT' : 'CO',
    currency: filterData.currency,
    startDate: filterData.startDate,
    endDate: filterData.endDate,
    count: filterData.count,
    duration: filterData.duration,
    ship: filterData.ship,
    port: filterData.port,
    region: filterData.region,
    seniorReqd: filterData.isSeniorCitizen,
    militaryReqd: filterData.isServiceMember,
    policeReqd: filterData.isPolice,
    fireFighterReqd: filterData.isFireFighter,
    stateCode: filterData.residentOf,
    loyaltyId: filterData.loyaltyId,
    loyaltyTierCode: filterData.loyaltyId ? filterData.loyaltyTier : '',
    sso: filterData.sso,
    sortBy: filterData.sortBy,
    countryCode: getAgentCountry(state) || get(ssoAgent, 'agent.country', ''),
    agencyId: get(ssoAgent, 'transactionData.agency.agencyId', ''),
    officeCode: get(ssoAgent, 'transactionData.officeCode', ''),
    occupancy: filterData.guestCount,
    country: getAgentCountry(state) || get(ssoAgent, 'agent.country', '')
  }
}
