import React, { Component } from 'react'
import { connect } from 'react-redux'

import formatPackageData from '../../../utilities/formatPackageData'
import setActiveStep, {
  setPreviousStep
} from '../../../actions/setActiveStepActions'
import removeActiveLoyalty from '../../../actions/removeActiveLoyalty'

import { setActiveRegion } from '../../../actions/setActiveSearchFilters'
import {
  resetActiveCabins,
  resetActiveEmailConfiguration,
  resetActiveFilters,
  resetActivePackages
} from '../../../actions/resetQuoteFlow'
import updateSearchParams from '../../../utilities/updateSearchParams'

import {
  setActivePagination,
  resetActivePagination,
  setActiveCount
} from '../../../actions/setActiveSearchFilters'
import fetchPackages from '../../../actions/fetchPackages'

import PageHeader from '../../elements/PageHeader'
import EQuoteSailingsFullSearchCruisesTab from './Tabs/EQuoteSailingsFullSearchCruisesTab'
import EQuoteSailingsFullSearchCruiseToursTab from './Tabs/EQuoteSailingsFullSearchCruiseToursTab'
import EQuoteSailingsFullSearchLandProgramsTab from './Tabs/EQuoteSailingsFullSearchLandProgramsTab'
import EQuoteSailingsFullSearchShipModal from './Modals/EQuoteSailingsFullSearchShipModal'
import EQuoteSailingsFullSearchFilterButtons from './Buttons/EQuoteSailingsFullSearchFilterButtons'
import EQuoteSailingsFullSearchPackageFilters from './EQuoteSailingsFullSearchPackageFilters'
import EQuotePackageDetailsContainer from '../../../containers/packageDetails/EQuotePackageDetailsContainer'
import EQuoteItineraryDetailsContainer from '../../../containers/itineraryDetails/EQuoteItineraryDetailsContainer'

import triggerCustomGaEvent from '../../../utilities/triggerCustomGaEvent'
import modalOpenedStopScroll from '../../../utilities/modalOpenedStopScroll'
import removeClassAllElements from '../../../utilities/removeClassAllElements'

import EQuoteSearchDisclaimer from '../../search/brand/EQuoteSearchDisclaimer'

import ArrowLeft from '../../../assets/icons/arrow-left.svg'
import ArrowRight from '../../../assets/icons/arrow-right.svg'
import '../../../styles/scss/base/tables.scss'
import '../../../styles/scss/components/equote-sailings-full-search.scss'
import '../../../styles/scss/components/equote-land-programs-detail-modal.scss'

import {
  getAvailableCruises,
  getAvailableCruiseTours
} from '../../../reducers/fetchPackagesReducers'
import { getDestinationsData } from '../../../sessionStorage/getDestinationsData'
import { getLabelsData } from '../../../sessionStorage/getLabelsData'
import { getShipsData } from '../../../sessionStorage/getShipsData'

import { getLandPackageCruiseList } from '../../../reducers/fetchLandPackagesReducer'
import EQuoteLandProgramDetailsModal from './Modals/EQuoteLandProgramDetailsModal'
import { currencyNames } from '../../../configuration/currencies'

const _ = require('lodash')

class EQuoteSailingsFullSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSailingsTab: 0,
      shipModalVisible: false,
      itineraryModalVisible: false,
      activeShipCode: null,
      detailsModalVisible: false,
      activeDetailsData: null,
      isCruiseTour: false,
      filters: {
        brand: null,
        ship: null,
        destination: null,
        nights: 0
      },
      lastOpenedSuperCat: '',
      activeLandPackage: null,
      landProgramModalIsOpen: false
    }

    this.togglePackageDetailsModal = this.togglePackageDetailsModal.bind(this)
  }

  //Handle filter term change
  handleFilterChangeTerm = e => {
    let { value, name } = e.target

    this.setState(prevState => ({
      ...prevState,
      filters: { ...prevState.filters, [name]: value }
    }))

    // Dispatch GA events
    triggerCustomGaEvent('equoteSearch-filterButton', { name, value })
  }

  handleToggleSailingsTab = tabIndex => {
    //Clear Filters
    this.setState(prevState => ({
      ...prevState,
      filters: {
        brand: null,
        ship: null,
        destination: null,
        nights: 0
      }
    }))

    this.setState({
      activeSailingsTab: tabIndex
    })

    // Dispatch GA events
    const tableName = !tabIndex
      ? 'cruises table'
      : tabIndex === 1
      ? 'Cruise Tours Tab'
      : 'Azamara Land Programs'
    triggerCustomGaEvent('equoteSearch-toggleCruiseTable', tableName)
  }

  // Trigger ship details modal
  handleOpenShipModal = shipCode => {
    this.setState({
      shipModalVisible: true,
      activeShipCode: shipCode
    })

    // Dispatch GA events
    triggerCustomGaEvent('equoteSearch-openShipModal', shipCode)
  }

  handleCloseShipModal = () => {
    this.setState({
      shipModalVisible: false,
      activeShipCode: null
    })

    // Dispatch GA events
    triggerCustomGaEvent('equoteSearch-dismissShipModal', true)
  }

  // Trigger itinerary details modal
  handleOpenPackageDetailsModal = (
    packageId,
    sailDate,
    brand,
    destinationCode,
    isCruiseTour,
    landPackageIdentifier
  ) => {
    this.scrollY = modalOpenedStopScroll(true)
    this.setState({
      detailsModalVisible: true,
      activeDetailsData: {
        id: packageId,
        sailDate,
        brand,
        destinationCode,
        isCruiseTour,
        landPackageIdentifier
      }
    })

    // Dispatch GA events
    const previewDetailsData = {
      id: packageId,
      sailDate: sailDate
    }
    triggerCustomGaEvent('equoteSearch-setSailDetails', previewDetailsData)
  }

  handleClosePackageDetailsModal = () => {
    modalOpenedStopScroll(false, this.scrollY)
    this.setState({
      detailsModalVisible: false,
      activeDetailsData: null
    })

    // Dispatch GA events
    triggerCustomGaEvent('equoteSearch-dismissSailDetails', true)
  }

  handleOpenItineraryPreviewModal = (
    packageId,
    sailDate,
    brand,
    identifier,
    showAdditionalCruiseTourDetails,
    activeLandPackage
  ) => {
    this.scrollY = modalOpenedStopScroll(true)
    this.setState({
      itineraryModalVisible: true,
      activeDetailsData: {
        id: packageId,
        sailDate,
        brand,
        identifier,
        showAdditionalCruiseTourDetails
      },
      activeLandPackage: activeLandPackage ? activeLandPackage : null
    })

    // Dispatch GA events
    const previewItineraryData = {
      id: packageId,
      sailDate: sailDate
    }
    triggerCustomGaEvent('equoteSearch-setSailItinerary', previewItineraryData)
  }

  handleCloseItineraryPreviewModal = () => {
    modalOpenedStopScroll(false, this.scrollY)
    this.setState({
      itineraryModalVisible: false,
      activeDetailsData: null,
      activeLandPackage: null
    })

    // Dispatch GA events
    triggerCustomGaEvent('equoteSearch-dismissSailItinerary', true)
  }

  toggleSuperClassDetails = (rowData, cabinClass) => {
    const targetRow = document.getElementById(
      `package-${rowData.id}-${rowData.departure}`
    )

    const rowHasOpenCell = Array.from(
      targetRow.querySelectorAll('.u-table-price-cell')
    ).some(cell => cell.classList.contains('active-price'))

    if (rowHasOpenCell) {
      targetRow.classList.add('has-active-cell')
    } else {
      targetRow.classList.remove('has-active-cell')
    }

    const targetSpan = document
      .getElementById(`package-${rowData.id}-${rowData.departure}`)
      .querySelector('td:first-child')
      .querySelector(`.${cabinClass}-icon-inner-span`)

    const isClosing = this.state.lastOpenedSuperCat === cabinClass

    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    })

    targetSpan.dispatchEvent(event)
    // Dispatch GA events
    const superCategoryData = {
      package: rowData,
      superCategory: cabinClass,
      opened: !isClosing
    }
    triggerCustomGaEvent('equoteSearch-toggleSuperCategory', superCategoryData)

    this.setState({ lastOpenedSuperCat: cabinClass })
  }

  toggleLandPackagesCabinTable(packageData, isClosing) {
    const packageRowId = packageData.landPackageIdentifier
      ? `land-package-${packageData.cruiseIdentifier}-${packageData.id}-${
          packageData.departure
        }`
      : `package-${packageData.id}-${packageData.departure}`

    const packageRow = document.getElementById(packageRowId)

    if (isClosing) {
      removeClassAllElements(packageRow, 'active-price')
    }
    if (packageRow) {
      const targetSpan = packageRow
        .querySelector('td:first-child')
        .querySelector(`.cabin-tables-trigger-inner-span`)
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      })
      targetSpan.dispatchEvent(event)
      triggerCustomGaEvent('equoteSearch-toggleLandPackage', packageData)
    }
  }

  handleResetQuoteFlow = () => {
    const { activeSearchFilterData, resetQuoteFlow } = this.props

    let resetQuery = {
      sso: activeSearchFilterData.sso
    }

    resetQuoteFlow()
    updateSearchParams(resetQuery)

    // Dispatch GA events
    triggerCustomGaEvent('equoteSearch-beginNewSearch', resetQuery)
  }

  handleBackToPriceSearch = () => {
    this.props.backToPriceSearch()
  }

  handleSetPageSize = size => {
    this.props.setActiveCount(size, this.props.ssoData)
  }

  handleSetPagination = pagination => {
    this.props.setPagination(pagination, this.props.ssoData)
  }

  repaintPseudoTableHead = (header, wrapper) => {
    // add pixels on header based on how much the table wrapper moved from top
    header.style.top = Math.round(window.scrollY - wrapper.offsetTop) + 'px'
  }

  // Position header sticky when scroll reach the table
  onScrollRepaintHead = () => {
    const wrapper = document.querySelector(
      '.equote__sailings-full-search-table-wrapper'
    )

    const tableType = !this.state.activeSailingsTab
      ? '.equote__sailings-cruises-table'
      : this.state.activeSailingsTab === 1
      ? '.equote__sailings-cruises-table'
      : '.equote__sailings-land-programs-table'

    let resultTable = document.querySelector(`${tableType} table`)
    let header = resultTable && resultTable.querySelector('thead')

    this.onTable = Boolean(
      wrapper &&
      wrapper.offsetTop < window.scrollY + 40 && // Only when scroll reach the table
        window.innerWidth < 1158 // Only for Mobile
    )

    if (this.onTable) {
      header.style.position = 'absolute'
      header.style.zIndex = 11
      this.repaintPseudoTableHead(header, wrapper) // add pixels to the top attribute
    } else {
      header && (header.style.position = 'relative')
    }
  }

  componentDidMount() {
    this.onTable = false
    window.addEventListener('scroll', this.onScrollRepaintHead)

    // Dispatch GA event for search component
    const trackingComponentData = {
      currentStep: 2,
      component: 'Available Sailings'
    }

    triggerCustomGaEvent(
      'equoteTracking-currentActiveStep',
      trackingComponentData
    )

    //Shadow update - toggle if close to the end of the scroll view
    const selectedTable =
      this.state.activeSailingsTab === 0
        ? '.equote__sailings-cruises-table'
        : this.state.activeSailingsTab === 1
        ? '.equote__sailings-cruise-tours-table'
        : '.equote__sailings-land-programs-table'

    const tableContainer = document.querySelector(selectedTable)

    const tableScrollContainer = tableContainer.getElementsByTagName('div')[2]
    if (tableContainer && tableScrollContainer) {
      if (tableScrollContainer) {
        tableScrollContainer.addEventListener('scroll', () => {
          this.toggleRightFade(tableScrollContainer)
        })

        this.toggleRightFade(tableScrollContainer)
      } else {
        const wrapper = document.querySelector(
          '.equote__sailings-full-search-table-wrapper'
        )

        wrapper && wrapper.classList.remove('show-fade')
      }
    }
  }

  componentDidUpdate() {
    window.addEventListener('scroll', this.onScrollRepaintHead)

    const selectedTable =
      this.state.activeSailingsTab === 0
        ? '.equote__sailings-cruises-table'
        : this.state.activeSailingsTab === 1
        ? '.equote__sailings-cruise-tours-table'
        : '.equote__sailings-land-programs-table'

    const tableContainer = document.querySelector(selectedTable)

    const tableScrollContainer = tableContainer.getElementsByTagName('div')[2]

    window.addEventListener('scroll', this.onScrollRepaintHead)
    tableScrollContainer.addEventListener('scroll', () => {
      this.toggleRightFade(tableScrollContainer)
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollRepaintHead)

    const selectedTable =
      this.state.activeSailingsTab === 0
        ? '.equote__sailings-cruises-table'
        : this.state.activeSailingsTab === 1
        ? '.equote__sailings-cruise-tours-table'
        : '.equote__sailings-land-programs-table'

    const tableContainer = document.querySelector(selectedTable)

    const tableScrollContainer = tableContainer.getElementsByTagName('div')[2]
    if (tableScrollContainer) {
      tableScrollContainer.removeEventListener('scroll', this.toggleRightFade)
    }
  }

  toggleRightFade(tableScrollContainer) {
    const container = document.querySelector(
      '.equote__sailings-full-search-table-wrapper'
    )
    const rightArrow = document.querySelector(
      '.equote__sailings-full-search-table-wrapper .scroll-right'
    )
    const leftArrow = document.querySelector(
      '.equote__sailings-full-search-table-wrapper .scroll-left'
    )

    if (tableScrollContainer.scrollLeft <= 0) {
      leftArrow && leftArrow.classList.add('u-element-hidden')
      container && container.classList.remove('show-fade-left')
    } else {
      leftArrow && leftArrow.classList.remove('u-element-hidden')
      container && container.classList.add('show-fade-left')
    }

    if (
      tableScrollContainer.scrollLeft + tableScrollContainer.clientWidth <
      tableScrollContainer.scrollWidth
    ) {
      container && container.classList.add('show-fade-right')
      rightArrow && rightArrow.classList.remove('u-element-hidden')
    } else {
      container && container.classList.remove('show-fade-right')
      rightArrow && rightArrow.classList.add('u-element-hidden')
    }
  }

  scrollTable = direction => {
    const selectedTable =
      this.state.activeSailingsTab === 0
        ? '.equote__sailings-cruises-table'
        : this.state.activeSailingsTab === 1
        ? '.equote__sailings-cruise-tours-table'
        : '.equote__sailings-land-programs-table'

    const tableContainer = document.querySelector(selectedTable)
    const tableScrollContainer = tableContainer.getElementsByTagName('div')[2]
    const table = tableScrollContainer.getElementsByTagName('table')[0]
    const offSet = tableScrollContainer.getBoundingClientRect().left
    const left = table.getBoundingClientRect().left
    const widthPerCell = table
      .querySelectorAll('.u-table-price-cell:not(.u-element-remove)')[0]
      .getBoundingClientRect().width
    const newLeft =
      direction === 'right'
        ? widthPerCell * 2 + offSet - left
        : offSet - left - widthPerCell * 2
    this.lastPositionLeft = newLeft
    tableScrollContainer.scrollTo({ left: newLeft, behavior: 'smooth' })
  }

  renderTitle(totalPackages, totalCruiseTours) {
    let title

    switch (this.state.activeSailingsTab) {
      case 0:
        title = `Showing Cruises ${totalPackages ? `(${totalPackages})` : ''}`
        break
      case 1:
        title = `Showing Cruisetours
      ${totalCruiseTours ? `(${totalCruiseTours})` : ''}`
        break
      default:
        return null
    }

    return title ? (
      <h1 className="u-remove-margin u-text-center title">{title}</h1>
    ) : null
  }

  renderTabs(totalPackages, totalCruiseTours) {
    if (totalPackages || totalCruiseTours) {
      return (
        <>
          {totalPackages ? (
            <button
              onClick={() => this.handleToggleSailingsTab(0)}
              className={
                this.state.activeSailingsTab === 0
                  ? 'equote__sailings-full-search-table-tab btn btn-primary'
                  : 'equote__sailings-full-search-table-tab btn btn-tertiary'
              }
            >
              {this.state.activeSailingsTab === 0 ? 'Showing' : 'Show'} Cruises
              ({totalPackages})
            </button>
          ) : null}
          {totalCruiseTours ? (
            <button
              onClick={() => {
                triggerCustomGaEvent('showCruiseToursClick', {})
                this.handleToggleSailingsTab(1)
              }}
              className={
                this.state.activeSailingsTab === 1
                  ? 'equote__sailings-full-search-table-tab btn btn-primary'
                  : 'equote__sailings-full-search-table-tab btn btn-tertiary'
              }
            >
              {this.state.activeSailingsTab === 1 ? 'Showing' : 'Show'}{' '}
              Cruisetours
              {totalCruiseTours ? ' (' + totalCruiseTours + ')' : null}
            </button>
          ) : null}
        </>
      )
    }

    return null
  }

  togglePackageDetailsModal(id) {
    triggerCustomGaEvent('togglePackageDetailsModal', {
      opening: !this.state.landProgramModalIsOpen,
      activeLandPackage: id
    })

    this.setState(state => ({
      landProgramModalIsOpen: !state.landProgramModalIsOpen,
      activeLandPackage: state.landProgramModalIsOpen ? null : id
    }))
  }

  render() {
    const {
      activeSearchFilterData,
      availablePackages,
      availableCruiseTours,
      destinationsData,
      shipsData,
      previousStep,
      availableLandPackages
    } = this.props

    const packages = formatPackageData(
        availablePackages,
        destinationsData,
        shipsData
      ),
      totalPackages = packages.length

    let cruiseTours = null,
      totalCruiseTours = null

    if (!_.isEmpty(availableCruiseTours)) {
      cruiseTours = formatPackageData(
        availableCruiseTours,
        destinationsData,
        shipsData
      )
      totalCruiseTours = cruiseTours.length
    }

    const landPrograms =
      availableLandPackages && Object.keys(availableLandPackages).length
        ? formatPackageData(
            availableLandPackages,
            destinationsData,
            shipsData,
            true
          )
        : null

    const totalLandPrograms = landPrograms ? landPrograms.length : 0
    const currencyName = currencyNames[activeSearchFilterData.currency]
    return (
      <>
        <div className="equote__sailings-full-search">
          <div className="u-content-container">
            <PageHeader
              buttonText={
                previousStep === 5
                  ? 'BACK TO PRICE SEARCH'
                  : 'Begin a New Search'
              }
              clickHandler={() =>
                previousStep === 5
                  ? this.handleBackToPriceSearch()
                  : this.handleResetQuoteFlow()
              }
              title="Available Sailings"
              subTitle={`Prices shown are lowest available for the criteria specified. Prices are in 
              ${currencyName || activeSearchFilterData.currency}.`}
            />
          </div>

          <EQuoteSailingsFullSearchPackageFilters />

          <div className="equote__sailings-full-search-table u-content-container">
            {this.renderTitle(totalPackages, totalCruiseTours)}

            <EQuoteSailingsFullSearchFilterButtons
              handleFilterChangeTerm={this.handleFilterChangeTerm}
              packages={packages}
              activeSailingsTab={this.state.activeSailingsTab}
              cruiseTours={cruiseTours}
              landPrograms={landPrograms}
            />
            <div className="equote__sailings-full-search-table-tabs u-table__tabs">
              {this.renderTabs(
                totalPackages,
                totalCruiseTours,
                totalLandPrograms
              )}
            </div>
            <div className="equote__sailings-full-search-table-wrapper">
              <button
                className="btn scroll-left"
                onClick={() => this.scrollTable('left')}
              >
                <img src={ArrowLeft} alt="scroll left" />
              </button>

              <button
                className="btn scroll-right"
                onClick={() => this.scrollTable('right')}
              >
                <img src={ArrowRight} alt="scroll right" />
              </button>

              {this.state.activeSailingsTab === 0 ? (
                <EQuoteSailingsFullSearchCruisesTab
                  /*
                   * Please note that if you add any props here that need to
                   * cause EQuoteSailingsFullSearchCruisesTab to re-render you'll
                   * also need to update shouldComponentUpdate in EQuoteSailingsFullSearchCruisesTab
                   * */
                  activeSearchFilterData={activeSearchFilterData}
                  filterTerms={this.state.filters}
                  packages={packages}
                  handleOpenShipModal={this.handleOpenShipModal}
                  handleOpenPackageDetailsModal={
                    this.handleOpenPackageDetailsModal
                  }
                  handleSetPagination={this.handleSetPagination}
                  handleSetPageSize={this.handleSetPageSize}
                  toggleSuperClassDetails={this.toggleSuperClassDetails}
                  handleOpenItineraryPreviewModal={
                    this.handleOpenItineraryPreviewModal
                  }
                />
              ) : this.state.activeSailingsTab === 1 &&
                availableCruiseTours &&
                !_.isEmpty(availableCruiseTours) ? (
                <EQuoteSailingsFullSearchCruiseToursTab
                  /*
                   * Please note that if you add any props here that need to
                   * cause EQuoteSailingsFullSearchCruiseToursTab to re-render you'll
                   * also need to update shouldComponentUpdate in EQuoteSailingsFullSearchCruiseToursTab
                   * */
                  activeSearchFilterData={activeSearchFilterData}
                  cruiseTours={cruiseTours}
                  filterTerms={this.state.filters}
                  handleOpenShipModal={this.handleOpenShipModal}
                  handleOpenPackageDetailsModal={
                    this.handleOpenPackageDetailsModal
                  }
                  toggleLandPackagesCabinTable={
                    this.toggleLandPackagesCabinTable
                  }
                  handleOpenItineraryPreviewModal={
                    this.handleOpenItineraryPreviewModal
                  }
                />
              ) : this.state.activeSailingsTab === 2 ? (
                <EQuoteSailingsFullSearchLandProgramsTab
                  landPrograms={landPrograms}
                  handleOpenShipModal={this.handleOpenShipModal}
                  filterTerms={this.state.filters}
                  handleOpenItineraryPreviewModal={
                    this.handleOpenItineraryPreviewModal
                  }
                  toggleLandPackagesCabinTable={
                    this.toggleLandPackagesCabinTable
                  }
                  handleOpenPackageDetailsModal={
                    this.handleOpenPackageDetailsModal
                  }
                  togglePackageDetailsModal={this.togglePackageDetailsModal}
                />
              ) : null}
            </div>

            <EQuoteSearchDisclaimer />
          </div>

          <EQuoteSailingsFullSearchShipModal
            activeShipCode={this.state.activeShipCode}
            handleCloseShipModal={this.handleCloseShipModal}
            shipModalVisible={this.state.shipModalVisible}
            shipsData={shipsData}
          />

          {this.state.detailsModalVisible ? (
            <EQuotePackageDetailsContainer
              activeDetailsData={this.state.activeDetailsData}
              handleClosePackageDetailsModal={
                this.handleClosePackageDetailsModal
              }
              detailsModalVisible={true}
              isCruiseTour={this.state.activeSailingsTab === 1}
            />
          ) : null}
          {this.state.itineraryModalVisible ? (
            <EQuoteItineraryDetailsContainer
              activeDetailsData={this.state.activeDetailsData}
              handleClosePackageDetailsModal={
                this.handleCloseItineraryPreviewModal
              }
              detailsModalVisible={true}
              isCruiseTour={this.state.activeSailingsTab === 1}
              activeLandPackage={
                this.state.activeLandPackage
                  ? availableLandPackages[this.state.activeLandPackage]
                  : null
              }
            />
          ) : null}

          {this.state.landProgramModalIsOpen && (
            <EQuoteLandProgramDetailsModal
              isOpen={true}
              activeLandPackage={
                availableLandPackages[this.state.activeLandPackage]
              }
              togglePackageDetailsModal={this.togglePackageDetailsModal}
              destinationsData={destinationsData}
            />
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  const cruiseRows = getAvailableCruises(state)
  const cruiseTourRows = getAvailableCruiseTours(state)
  const availableLandPackages = getLandPackageCruiseList(state)

  return {
    activeSearchFilterData: state.activeSearchFilterData,
    activeStep: state.activeStepData.activeStep,
    previousStep: state.activeStepData.previousStep,
    shipsData: getShipsData(),
    labelsData: getLabelsData(),
    destinationsData: getDestinationsData(),
    availablePackages: cruiseRows,
    availableCruiseTours: cruiseTourRows,
    availableLandPackages,
    ssoData: state.ssoData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetQuoteFlow() {
      dispatch(resetActiveCabins())
      dispatch(resetActivePackages())
      dispatch(resetActiveFilters())
      dispatch(resetActiveEmailConfiguration())
      dispatch(setActiveStep(0))
      dispatch(removeActiveLoyalty())
      dispatch(setPreviousStep(null))
      dispatch(resetActivePagination())
    },
    setPagination(pagination, ssoData) {
      dispatch(setActivePagination(pagination))
      dispatch(fetchPackages(ssoData))
    },
    setActiveCount(count, ssoData) {
      dispatch(setActiveCount(count))
      dispatch(fetchPackages(ssoData))
    },
    backToPriceSearch() {
      return dispatch(setActiveStep(5), dispatch(setActiveRegion('')))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteSailingsFullSearch)
