import React, { useState, useEffect, useRef } from 'react'

import FirstPage from '../../../../assets/icons/paginate-first-page.svg'
import PrevPage from '../../../../assets/icons/pagination-previous-page.svg'
import NextPage from '../../../../assets/icons/pagination-next-page.svg'
import LastPage from '../../../../assets/icons/pagination-last-page.svg'

const pageSizes = [5, 20, 30, 50]

export const EQuoteSailingsFullSearchPagination = ({
  searchFilterData,
  onPaginate,
  onSetPageSize
}) => {
  const menuRef = useRef(null)
  const [show, setShow] = useState(false)
  const { page, pages, perPage, total } = searchFilterData
  const start = (page - 1) * perPage + 1
  const end = page !== pages ? page * perPage : total

  const isPrevDisabled = () => {
    return page === 1 ? 'disabled' : ''
  }

  const isNextDisabled = () => {
    return page * perPage > total ? 'disabled' : ''
  }

  const isSelected = item => item === perPage

  const handlePageSize = (e, size) => {
    e.stopPropagation()
    // handle page size dispatch
    onSetPageSize(size)
    setShow(false)
  }

  useEffect(() => {
    const handleBodyClick = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShow(false)
      }
    }
    document.body.addEventListener('click', handleBodyClick)
    return () => {
      document.body.removeEventListener('click', handleBodyClick)
    }
  }, [])

  return (
    <td className="pagination-cell">
      <div className="pagination-container">
        <div className="page-size" onClick={() => setShow(true)}>
          <div>{perPage} rows</div>
          <div className="down-arrow" />
          <div
            ref={menuRef}
            className={`size-drop-down ${show ? 'show-menu' : ''}`}
          >
            <ul>
              {pageSizes.map(item => {
                return (
                  <li
                    className={`${isSelected(item) ? 'selected' : ''}`}
                    key={item}
                    onClick={e => handlePageSize(e, item)}
                  >
                    {item}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div className="pagination">
          <div
            className={`pagination-item firstPage ${isPrevDisabled()}`}
            onClick={() => onPaginate('first')}
          >
            <img src={FirstPage} alt="first page" />
            <div className="tooltip">First Page</div>
          </div>
          <div
            className={`pagination-item prevPage ${isPrevDisabled()}`}
            onClick={() => onPaginate('prev')}
          >
            <img src={PrevPage} alt="previous page" />
            <div className="tooltip">Previous Page</div>
          </div>
          <div className="page">
            {start}-{end} of {total}
          </div>
          <div
            className={`pagination-item nextPage ${isNextDisabled()}`}
            onClick={() => onPaginate('next')}
          >
            <img src={NextPage} alt="next page" />
            <div className="tooltip">Next Page</div>
          </div>
          <div
            className={`pagination-item lastPage ${isNextDisabled()}`}
            onClick={() => onPaginate('last')}
          >
            <img src={LastPage} alt="last page" />
            <div className="tooltip">Last Page</div>
          </div>
        </div>
      </div>
    </td>
  )
}
