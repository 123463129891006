import React, { Component, createRef } from 'react'
import MaterialTable, { MTableBodyRow } from 'material-table'

import { EQuoteSailingsFullSearchPagination } from './EQuoteSailingsFullSearchPagination'

import EQuoteSailingsSelectPackageButton from '../Buttons/EQuoteSailingsSelectPackageButton'
import EQuoteSailingsFullSearchCabins from '../Cabins/EQuoteSailingsFullSearchCabins'

import moment from 'moment'
import EQuoteTablePriceCell from '../EQuoteTablePriceCell'
import EQuoteTablePreviewButtonCell from '../Buttons/EQuoteTablePreviewButtonCell'

import removeClassAllElements from '../../../../utilities/removeClassAllElements'
import getArrayElementsByClass from '../../../../utilities/getArrayElementsByClass'

const pricingCellStyles = {
  cellStyle: {
    paddingTop: 0,
    paddingBottom: 10,
    paddingLeft: 0,
    paddingRight: 0,
    width: 132,
    position: 'relative'
  },
  headerStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
    width: 132,
    zIndex: 12
  }
}

class EQuoteSailingsFullSearchCruisesTab extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.onOrderChangeFlag = false
    this.tableRef = createRef()
  }

  shouldComponentUpdate(nextProps) {
    /*
     * we need this to prevent the table from re-rendering if the click handlers
     * change (which they do on every parent update)
     * as that will cause the price cells to make duplicate ajax request
     * when using unsupportedQualifiers.
     * This currently causes the table to never update because it un-mounts
     * and remounts with every new search
     */

    if (this.props.filterTerms !== nextProps.filterTerms) {
      this.tableRef &&
        this.tableRef.current &&
        this.tableRef.current.dataManager &&
        (this.tableRef.current.dataManager.currentPage = 0)
      return true
    }

    return false
  }

  onSetPageSize = size => {
    const { activeSearchFilterData, handleSetPageSize } = this.props
    const { perPage } = activeSearchFilterData
    if (perPage !== size) handleSetPageSize(size)
  }

  onPaginate = action => {
    const { activeSearchFilterData, handleSetPagination } = this.props
    const { page, pages, perPage, total } = activeSearchFilterData
    const pagination = {
      page,
      pages,
      perPage,
      total
    }

    switch (action) {
      case 'first':
        pagination.page = 1
        break

      case 'prev':
        pagination.page = Math.max(1, pagination.page - 1)
        break

      case 'next':
        pagination.page = Math.min(pages, pagination.page + 1)
        break

      case 'last':
        pagination.page = pages
        break

      default:
    }

    if (activeSearchFilterData.page !== pagination.page) {
      handleSetPagination(pagination)
    }
  }

  // On table change (e.g click in any cell header), simulate click on each previous row that has a
  // detail panel opened to call 'OnRowClick'
  onTableChange = (e, action) => {
    console.log('event', e, action)

    const rowsWithClassActive = getArrayElementsByClass(
      '.equote__sailings-full-search--table-body-row ~ tr:not(.equote__sailings-full-search--table-body-row)'
    )

    rowsWithClassActive.forEach(row => {
      this.onOrderChangeFlag = true
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      })
      //Remove all active price cells
      row.previousSibling.dispatchEvent(event)
    })
    removeClassAllElements(document, 'active-price')
    this.onChangePageScroll()
  }

  //Get the detail panel, simulate a click on the active cell to close the panel
  onRowClick = (event, rowData) => {
    const detailPanel =
      event.currentTarget.nextSibling &&
      event.currentTarget.nextSibling.querySelector(
        '.equote__sailings-full-search-cabins-table'
      )
    if (this.onOrderChangeFlag && detailPanel && detailPanel.dataset.type) {
      removeClassAllElements(event.currentTarget, 'active-price')
      this.props.toggleSuperClassDetails(rowData, detailPanel.dataset.type)
    }

    this.onOrderChangeFlag = false
  }

  onChangePageScroll = () => {
    const resultTable = document.querySelector(
      '.equote__sailings-full-search-table'
    )
    resultTable &&
      resultTable.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
  }

  renderDateCol = () => {
    const {
      activeSearchFilterData,
      handleOpenItineraryPreviewModal
    } = this.props

    if (activeSearchFilterData && activeSearchFilterData.sortBy === 'PRICE') {
      return {
        title: 'Date',
        field: 'departure',
        type: 'date',
        cellStyle: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 10,
          paddingRight: 10,
          minWidth: 110,
          maxWidth: 110
        },
        headerStyle: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 10,
          paddingRight: 10,
          minWidth: 110,
          maxWidth: 110,
          zIndex: 12
        },
        render: rowData => {
          return (
            <button
              className="btn btn-text u-font-uppercase"
              onClick={() =>
                handleOpenItineraryPreviewModal(
                  rowData.id,
                  rowData.sailDate,
                  rowData.brand,
                  rowData.identifier,
                  false
                )
              }
            >
              {moment(rowData.startDate).format('DDMMMYYYY')}
            </button>
          )
        }
      }
    } else {
      return {
        title: 'Date',
        field: 'departure',
        type: 'date',
        defaultSort: 'asc',
        cellStyle: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 10,
          paddingRight: 10,
          minWidth: 110,
          maxWidth: 110
        },
        headerStyle: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 10,
          paddingRight: 10,
          minWidth: 110,
          maxWidth: 110,
          zIndex: 12
        },
        render: rowData => {
          return (
            <button
              className="btn btn-text u-font-uppercase"
              onClick={() =>
                handleOpenItineraryPreviewModal(
                  rowData.id,
                  rowData.departure,
                  rowData.brand,
                  rowData.identifier,
                  false
                )
              }
            >
              {moment(rowData.departure).format('DDMMMYYYY')}
            </button>
          )
        }
      }
    }
  }

  getSuperCategoryFullName = (rowData, superCatCode) => {
    if (!rowData || !superCatCode) {
      return null
    }
    const pricing =
      rowData && rowData.pricing && rowData.pricing[superCatCode.toUpperCase()]
    return pricing && pricing.category && pricing.category.name
      ? pricing.category.name
      : pricing && pricing.stateroomDescription
  }

  render() {
    const {
      packages,
      handleOpenShipModal,
      handleOpenPackageDetailsModal,
      toggleSuperClassDetails,
      activeSearchFilterData
    } = this.props

    return (
      <MaterialTable
        tableRef={this.tableRef}
        onOrderChange={this.onTableChange}
        onRowClick={this.onRowClick}
        onChangePage={this.onTableChange}
        onChangeRowsPerPage={this.onTableChange}
        components={{
          Container: props => (
            <div className="equote__sailings-cruises-table u-table hide-details-icons hide-filter-row">
              {props.children}
            </div>
          ),
          Row: props => {
            return (
              <MTableBodyRow
                /**
                 * We need the secondary-background class because MaterialTable will add a new <tr />
                 * when we toggle open the details which makes nth-child unreliable
                 **/
                className={`equote__sailings-full-search--table-body-row primary-row package-${
                  props.data.id
                }-${props.data.departure} ${
                  Number.isInteger((props.index + 1) / 2)
                    ? 'secondary-background'
                    : ''
                }`}
                id={`package-${props.data.id}-${props.data.departure}`}
                {...props}
              />
            )
          },
          Pagination: props => (
            <EQuoteSailingsFullSearchPagination
              {...props}
              searchFilterData={activeSearchFilterData}
              onPaginate={this.onPaginate}
              onSetPageSize={this.onSetPageSize}
            />
          )
        }}
        options={{
          paging: true,
          pageSize: 30,
          showEmptyDataSourceMessage: true,
          toolbar: false,
          filtering: true,
          // paginationType: 'stepped',
          emptyRowsWhenPaging: false
        }}
        localization={{
          body: {
            emptyDataSourceMessage:
              'No sailings available, please try another date range.'
          }
        }}
        columns={[
          {
            title: 'Brand',
            field: 'brandName',
            defaultFilter: this.props.filterTerms.brand,
            cellStyle: {
              display: 'none'
            },
            headerStyle: {
              display: 'none'
            }
          },
          {
            title: 'Select up to 5',
            field: 'id',
            sorting: false,
            cellStyle: {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 10,
              paddingRight: 10,
              textAlign: 'center',
              minWidth: 90,
              maxWidth: 90,
              width: 90
            },
            headerStyle: {
              padding: 20,
              minWidth: 90,
              maxWidth: 90,
              width: 90,
              zIndex: 12
            },
            render: packageData => {
              return (
                <EQuoteSailingsSelectPackageButton
                  identifier={packageData.identifier}
                  isLandPackage={false}
                  stateKey={packageData.stateKey}
                  isCruiseTour={!packageData.justACruise}
                />
              )
            }
          },
          this.renderDateCol(),
          {
            title: 'Nights',
            defaultFilter: this.props.filterTerms.nights,
            field: 'nights',
            type: 'numeric',
            cellStyle: {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 10,
              paddingRight: 10,
              margin: 'auto',
              minWidth: 98,
              maxWidth: 98
            },
            headerStyle: {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 10,
              paddingRight: 10,
              minWidth: 98,
              maxWidth: 98,
              zIndex: 12
            }
          },
          {
            title: 'Destination',
            defaultFilter: this.props.filterTerms.destination,
            field: 'destinationName',
            cellStyle: {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 10,
              paddingRight: 10,
              minWidth: 121
            },
            headerStyle: {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 10,
              paddingRight: 10,
              minWidth: 121,
              zIndex: 12
            }
          },
          {
            title: 'Ship',
            defaultFilter: this.props.filterTerms.ship,
            field: 'shipName',
            cellStyle: {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 10,
              paddingRight: 10,
              minWidth: 112
            },
            headerStyle: {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 10,
              paddingRight: 10,
              minWidth: 112,
              zIndex: 12
            },
            render: rowData => {
              return (
                <button
                  onClick={() => handleOpenShipModal(rowData.shipCode)}
                  className="btn btn-text"
                  tabIndex={0}
                >
                  {rowData.shipName}
                </button>
              )
            }
          },
          {
            title: 'Interior',
            field: 'interiorCategoryData',
            type: 'numeric',
            sorting: false,
            ...pricingCellStyles,
            render: rowData => {
              const stateroomExists = rowData.stateroomTypes
                ? rowData.stateroomTypes.find(
                    stateRoom => stateRoom.code === 'I'
                  )
                : null

              return (
                <EQuoteTablePriceCell
                  packageIdentifier={`${rowData.id}-${rowData.sailDate}`}
                  isCruiseTour={false}
                  stateroomType={'I'}
                  onClick={() => {
                    toggleSuperClassDetails(rowData, 'interior')
                  }}
                  stateroomExists={stateroomExists}
                />
              )
            }
          },
          {
            title: 'Oceanview',
            field: 'oceanviewCategoryData',
            type: 'numeric',
            sorting: false,
            ...pricingCellStyles,
            render: rowData => {
              const stateroomExists = rowData.stateroomTypes
                ? rowData.stateroomTypes.find(
                    stateRoom => stateRoom.code === 'O'
                  )
                : null
              return (
                <EQuoteTablePriceCell
                  packageIdentifier={`${rowData.id}-${rowData.sailDate}`}
                  isCruiseTour={false}
                  stateroomType={'O'}
                  onClick={() => {
                    toggleSuperClassDetails(rowData, 'ocean')
                  }}
                  stateroomExists={stateroomExists}
                />
              )
            }
          },
          {
            title: 'Balcony',
            field: 'balconyCategoryData',
            type: 'numeric',
            sorting: false,
            ...pricingCellStyles,
            render: rowData => {
              const stateroomExists = rowData.stateroomTypes
                ? rowData.stateroomTypes.find(
                    stateRoom => stateRoom.code === 'B'
                  )
                : null
              return (
                <EQuoteTablePriceCell
                  packageIdentifier={`${rowData.id}-${rowData.sailDate}`}
                  isCruiseTour={false}
                  stateroomType={'B'}
                  onClick={() => {
                    toggleSuperClassDetails(rowData, 'balcony')
                  }}
                  stateroomExists={stateroomExists}
                />
              )
            }
          },
          {
            title: 'Deluxe / Suite',
            field: 'suiteCategoryData',
            type: 'numeric',
            sorting: false,
            ...pricingCellStyles,
            render: rowData => {
              const stateroomExists = rowData.stateroomTypes
                ? rowData.stateroomTypes.find(
                    stateRoom => stateRoom.code === 'D'
                  )
                : null

              return (
                <EQuoteTablePriceCell
                  packageIdentifier={`${rowData.id}-${rowData.sailDate}`}
                  isCruiseTour={false}
                  stateroomType={'D'}
                  onClick={() => {
                    toggleSuperClassDetails(rowData, 'deluxe')
                  }}
                  stateroomExists={stateroomExists}
                />
              )
            }
          },
          {
            title: '',
            field: 'preview',
            loadingType: 'linear',
            sorting: false,
            cellStyle: {
              padding: '0 20px',
              textAlign: 'center',
              minWidth: 79
            },
            headerStyle: {
              padding: '0 20px',
              minWidth: 103,
              zIndex: 12
            },
            render: rowData => {
              const {
                id,
                departure,
                brand,
                destinationCode,
                identifier,
                justACruise
              } = rowData
              return (
                <EQuoteTablePreviewButtonCell
                  identifier={identifier}
                  justACruise={justACruise}
                  onClick={() =>
                    handleOpenPackageDetailsModal(
                      id,
                      departure,
                      brand,
                      destinationCode
                    )
                  }
                />
              )
            }
          }
        ]}
        data={packages}
        detailPanel={[
          {
            icon: () => <span className="interior-icon-inner-span">i</span>,
            render: rowData => {
              return (
                <div
                  style={{
                    backgroundColor: (() => {
                      const el = document.getElementById(
                        `package-${rowData.id}-${rowData.departure}`
                      )
                      return el && getComputedStyle(el).backgroundColor
                    })()
                  }}
                >
                  <EQuoteSailingsFullSearchCabins
                    key={rowData.id + '-INTERIOR-' + rowData.departure}
                    superCategory="I"
                    superCategoryFullName={this.getSuperCategoryFullName(
                      rowData,
                      'I'
                    )}
                    type="interior"
                    packageData={rowData}
                    stateKey="packagesData"
                    keyIdentifier="-INTERIOR-"
                    closeHandler={() => {
                      removeClassAllElements(
                        document.getElementById(
                          `package-${rowData.id}-${rowData.departure}`
                        ),
                        'active-price'
                      )
                      toggleSuperClassDetails(rowData, 'interior')
                    }}
                  />
                </div>
              )
            }
          },
          {
            icon: () => <span className="ocean-icon-inner-span">o</span>,
            render: rowData => {
              return (
                <div
                  style={{
                    backgroundColor: (() => {
                      const el = document.getElementById(
                        `package-${rowData.id}-${rowData.departure}`
                      )
                      return el && getComputedStyle(el).backgroundColor
                    })()
                  }}
                >
                  <EQuoteSailingsFullSearchCabins
                    key={rowData.id + '-OUTSIDE-' + rowData.departure}
                    superCategory="O"
                    superCategoryFullName={this.getSuperCategoryFullName(
                      rowData,
                      'O'
                    )}
                    type="ocean"
                    packageData={rowData}
                    stateKey="packagesData"
                    keyIdentifier="-OUTSIDE-"
                    closeHandler={() => {
                      removeClassAllElements(
                        document.getElementById(
                          `package-${rowData.id}-${rowData.departure}`
                        ),
                        'active-price'
                      )
                      toggleSuperClassDetails(rowData, 'ocean')
                    }}
                  />
                </div>
              )
            }
          },
          {
            icon: () => <span className="balcony-icon-inner-span">b</span>,
            render: rowData => {
              return (
                <div
                  style={{
                    backgroundColor: (() => {
                      const el = document.getElementById(
                        `package-${rowData.id}-${rowData.departure}`
                      )
                      return el && getComputedStyle(el).backgroundColor
                    })()
                  }}
                >
                  <EQuoteSailingsFullSearchCabins
                    key={rowData.id + '-BALCONY-' + rowData.departure}
                    superCategory="B"
                    superCategoryFullName={this.getSuperCategoryFullName(
                      rowData,
                      'B'
                    )}
                    type="balcony"
                    packageData={rowData}
                    stateKey="packagesData"
                    keyIdentifier="-BALCONY-"
                    closeHandler={() => {
                      removeClassAllElements(
                        document.getElementById(
                          `package-${rowData.id}-${rowData.departure}`
                        ),
                        'active-price'
                      )
                      toggleSuperClassDetails(rowData, 'balcony')
                    }}
                  />
                </div>
              )
            }
          },
          {
            icon: () => <span className="deluxe-icon-inner-span">d</span>,
            render: rowData => {
              return (
                <div
                  style={{
                    backgroundColor: (() => {
                      const el = document.getElementById(
                        `package-${rowData.id}-${rowData.departure}`
                      )
                      return el && getComputedStyle(el).backgroundColor
                    })()
                  }}
                >
                  <EQuoteSailingsFullSearchCabins
                    key={rowData.id + '-DELUXE-' + rowData.departure}
                    superCategory="D"
                    superCategoryFullName={this.getSuperCategoryFullName(
                      rowData,
                      'D'
                    )}
                    type="deluxe"
                    packageData={rowData}
                    stateKey="packagesData"
                    keyIdentifier="-DELUXE-"
                    closeHandler={() => {
                      removeClassAllElements(
                        document.getElementById(
                          `package-${rowData.id}-${rowData.departure}`
                        ),
                        'active-price'
                      )
                      toggleSuperClassDetails(rowData, 'deluxe')
                    }}
                  />
                </div>
              )
            }
          }
        ]}
      />
    )
  }
}

export default EQuoteSailingsFullSearchCruisesTab
