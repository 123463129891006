import React, { Component } from 'react'
import { connect } from 'react-redux'
import clearUnsupportedQualifierPrices from '../../../actions/clearUnsupportedQualifierPrices'
import {
  setActiveBrand,
  setActiveRegion,
  setActiveStartDate
} from '../../../actions/setActiveSearchFilters'

import updateSearchParams from '../../../utilities/updateSearchParams'
import setActiveStep from '../../../actions/setActiveStepActions'
import fetchPackages from '../../../actions/fetchPackages'
import { getBrandsData } from '../../../sessionStorage/getBrandsData'
import triggerCustomGaEvent from '../../../utilities/triggerCustomGaEvent'

import shipIcon from '../../../assets/icons/ship.svg'

import moment from 'moment'
import { getLabelsData } from '../../../sessionStorage/getLabelsData'
import { getDisabledBrandsFromSearchCriteria } from '../../../reducers/fetchSsoReducers'

class EQuoteSearchFilterBrand extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  // Submit quick brand query
  submitQuickBrandQuery(brand) {
    const {
      activeSearchFilterData,
      handleActiveBrand,
      handleActiveStartDate,
      handleSubmitQueryPackages,
      clearUnsupportedQualifierPrices,
      loyaltyData,
      ssoData
    } = this.props
    const startDate = moment().format('YYYY-MM-DD'),
      brandQuery = {
        brand: brand,
        bookingType: activeSearchFilterData.bookingType,
        count: activeSearchFilterData.count,
        cruiseType: activeSearchFilterData.cruiseType,
        currency: activeSearchFilterData.currency,
        startDate: startDate,
        sso: activeSearchFilterData.sso
      }
    clearUnsupportedQualifierPrices()
    handleActiveBrand(brand)
    handleActiveStartDate(startDate)
    updateSearchParams(brandQuery)
    handleSubmitQueryPackages(ssoData)

    // Dispatch initial GA events
    triggerCustomGaEvent('equoteSearch-brand', {
      ...brandQuery,
      ...loyaltyData
    })
  }

  renderBrands = () => {
    const { brands, disabledBrands } = this.props

    if (!brands) return []

    return brands
      .filter(brand => brand.brand_code !== 'D')
      .map(brand => (
        <button
          onClick={() => this.submitQuickBrandQuery(brand.brand_code)}
          key={`${brand.brand_code}`}
          className="equote__search-filter equote__search-filter--multi"
          disabled={
            disabledBrands &&
            disabledBrands.some(brandCode => brandCode === brand.brand_code)
          }
        >
          <img src={brand.badge_dark.file} alt="Ship icon" />
          <p className="equote__search-filter-title u-remove-margin u-font-medium">
            Search
          </p>
        </button>
      ))
  }

  render() {
    const { labelsData } = this.props

    let searchLabels = null

    if (labelsData && labelsData.search) {
      searchLabels = labelsData.search
    }

    return (
      <div className="equote__search-filter-group equote__search-filter-group--brand">
        <div className="equote__search-filter-icon">
          <img src={shipIcon} alt="Ship icon" />
        </div>

        <h6 className="u-small-title">
          {searchLabels && searchLabels.by_brand_title
            ? searchLabels.by_brand_title
            : 'Search By Brand'}
        </h6>
        <div className="equote__search-filter brands">
          {this.renderBrands()}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const getDisabledBrands = getDisabledBrandsFromSearchCriteria(state)
  const disabledBrands = getDisabledBrands(state.activeSearchFilterData)
  return {
    activeSearchFilterData: state.activeSearchFilterData,
    brands: getBrandsData(),
    labelsData: getLabelsData(),
    loyaltyData: state.loyaltyData,
    disabledBrands,
    ssoData: state.ssoData
  }
}

const mapDispatchToProps = dispatch => ({
  handleActiveBrand(brand) {
    return dispatch(setActiveBrand(brand))
  },
  handleActiveDestination(destination) {
    return dispatch(setActiveRegion(destination))
  },
  handleActiveStartDate(startDate) {
    return dispatch(setActiveStartDate(startDate))
  },
  handleSubmitQueryPackages(ssoData) {
    dispatch(fetchPackages(ssoData))
    dispatch(setActiveStep(1))
  },
  clearUnsupportedQualifierPrices() {
    dispatch(clearUnsupportedQualifierPrices())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteSearchFilterBrand)
