import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'

import EQuoteAgentDetailsDrawer from '../agent/EQuoteAgentDetailsDrawer'

import eConnectLogo from '../../assets/images/equote-logo.svg'
import agentProfileIcon from '../../assets/icons/agent-profile.svg'

import '../../styles/scss/components/equote-header.scss'
import updateSearchParams from '../../utilities/updateSearchParams'
import {
  resetActiveCabins,
  resetActiveEmailConfiguration,
  resetActiveFilters,
  resetActivePackages
} from '../../actions/resetQuoteFlow'
import setActiveStep, {
  setPreviousStep
} from '../../actions/setActiveStepActions'
import { getBrandsData } from '../../sessionStorage/getBrandsData'
import { CLEAR_PACKAGE_DATA } from '../../actions/fetchPackages'
import { resetActivePagination } from '../../actions/setActiveSearchFilters'

let _ = require('lodash')

class EQuoteHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAgentDrawerOpen: false
    }
  }

  handleToggleAgentDrawer = () => {
    if (this.state.isAgentDrawerOpen) {
      document.body.classList.remove('drawer-open')
      document.documentElement.classList.remove('ReactModal__Html--open')
    } else {
      document.body.classList.add('drawer-open')
      document.documentElement.classList.add('ReactModal__Html--open')
    }
    this.setState({
      isAgentDrawerOpen: !this.state.isAgentDrawerOpen
    })
  }

  // Build tri-brand image and links to marketing
  renderBrandLinks() {
    const { brands } = this.props
    let brandLogos = []

    for (const brand of brands) {
      if (brand.brand_code !== 'D') {
        brandLogos.push(
          <div key={brand.brand_code} className="equote__header-brand">
            <img
              className="u-img-responsive"
              src={brand.badge_light.file}
              alt={brand.name}
            />
          </div>
        )
      }
    }

    return brandLogos
  }

  handleResetQuoteFlow = () => {
    const { activeSearchFilterData, resetQuoteFlow } = this.props

    let resetQuery = {
      sso: activeSearchFilterData.sso
    }

    resetQuoteFlow()
    updateSearchParams(resetQuery)
  }

  render() {
    const { modifiedSsoData, ssoData, brands } = this.props
    let agentName = null

    // Set Agent data edit VIA edit agent response, content hub or ESL
    if (
      modifiedSsoData &&
      modifiedSsoData.agent &&
      modifiedSsoData.agent.name
    ) {
      agentName = modifiedSsoData.agent.name
    } else if (ssoData && ssoData.agent && ssoData.agent.name) {
      agentName = ssoData.agent.name
    }

    return (
      <Fragment>
        <div className="equote__header">
          <div className="equote__header-container u-content-container">
            <div className="equote__header_user_logo_container">
              {!this.props.isMaintenance && (
                <div
                  onClick={() => this.handleToggleAgentDrawer()}
                  className="equote__agent-toggle"
                >
                  <div className="equote__agent-icon">
                    <img src={agentProfileIcon} alt="Agent Profile Icon" />
                  </div>
                  <div className="equote__agent-info">
                    Welcome {agentName ? `${agentName}!` : null}
                  </div>
                </div>
              )}

              <div className="equote__header-logo">
                <button
                  onClick={
                    Boolean(this.props.activeStep)
                      ? this.handleResetQuoteFlow
                      : () => {}
                  }
                >
                  <img
                    className="u-img-responsive"
                    src={eConnectLogo}
                    alt="Royal Caribbean eConnect"
                  />
                </button>
              </div>
            </div>

            <div className="equote__header-brands">
              {!_.isEmpty(brands) ? this.renderBrandLinks() : null}
            </div>
          </div>
        </div>

        <EQuoteAgentDetailsDrawer
          ssoData={ssoData}
          isAgentDrawerOpen={this.state.isAgentDrawerOpen}
          handleToggleAgentDrawer={this.handleToggleAgentDrawer}
        />
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    activeSearchFilterData: state.activeSearchFilterData,
    activeStep: state.activeStepData.activeStep,
    brands: getBrandsData(),
    modifiedSsoData: state.modifiedSsoData,
    ssoData: state.ssoData
  }
}

const mapDispatchToProps = dispatch => ({
  resetQuoteFlow() {
    return (
      dispatch(resetActiveCabins()),
      dispatch(resetActivePackages()),
      dispatch(resetActiveFilters()),
      dispatch(resetActiveEmailConfiguration()),
      dispatch(setActiveStep(0)),
      dispatch(setPreviousStep(null)),
      dispatch({ type: CLEAR_PACKAGE_DATA }),
      dispatch(resetActivePagination())
    )
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EQuoteHeader)
